@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Roboto:wght@300&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.App{
  /* padding: 5px; */
  background-image: linear-gradient(to right, #f5f7fa, #e8edf4, #dce3ee, #cfd9e8, #c3cfe2);
  font-family: 'Merriweather', serif;
  font-family: 'Roboto', sans-serif;
  min-width: fit-content;
}

.header{
  color: rgb(87, 87, 87);
  font-size: 50px;
  text-align: left;
  padding: 5px;
  margin-top: 40px ;
  margin-left: 80px;


}

.university_link{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  margin: 10px 50px;
  align-items:flex-start;
  /* border-radius: 35px; */
}

.card{

  margin: 10px;
  display: flex;
  height: 120px;
  border-radius: 15px !important;
  padding: 10px;
  
}

.individual_card{
  font-weight: 500;
  color: rgb(56, 55, 55) ;

}

.list{
  list-style:decimal;
  padding: 12px;
  font-size: 30px;
}

a{
  text-decoration: none;
  color: black;
}

li:hover{
  transform: scale(1.25);
  transition-duration: 0.5s;
  
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .App{
    width: 100%;
  }
}