.header_nav{
  background-color: rgb(82, 81, 81);
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 15px;
}

.header_name{
  margin-left: 20px;
  font-size: 40px;
}

.header_links{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
}

.header_list{
  list-style: none;
  padding-left: 20px;
  font-size: 20px;
  margin-left: 60px;
  cursor: pointer;
}


